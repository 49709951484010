<template>
    <div class="form-container">
        <!-- 위탁 신청 전에 읽어주세요 -->
        <div>
            <v-card tile elevation="0" class="grey-ef">
                <v-card-text class="pa-24 pa-md-30">
                    <div class="mb-10 mb-md-14">
                        <h4 class="tit tit--xs primary--text">* 위탁 신청 전에 읽어주세요</h4>
                    </div>
                    <div class="page-text">
                        <p>1. 심의 항목 : 작품성, 작품상태, 판매가능성, 작품가치, 판매대상 범위와의 부합성 등 다양한 측면을 고려하여 서면심의를 진행합니다</p>
                        <p>2. 심의 기간 : 접수일로부터 7일 이내 심의를 완료합니다</p>
                        <p>3. 심의 결과 안내 : 위탁신청 접수 내용을 토대로 심의 후, 위탁 가능 여부를 위탁자에게 개별 안내해 드립니다</p>
                    </div>
                </v-card-text>
            </v-card>
        </div>
        <!-- 위탁 신청 전에 읽어주세요 -->

        <!-- 위탁 신청인 정보 -->
        <div class="mt-30 mt-md-40">
            <div class="mb-10">
                <h3 class="tit tit--xs">위탁 신청인 정보</h3>
            </div>
            <div class="table-style table-style--default">
                <myinfo-modify-row label="이름">
                    {{ form.meta.name }}
                </myinfo-modify-row>

                <myinfo-modify-row label="연락처">
                    <div class="mw-lg-500px">
                        {{ form.meta.phone }}
                    </div>
                </myinfo-modify-row>
                <myinfo-modify-row label="이메일">
                    <div class="mw-lg-500px">
                        {{ form.meta.email }}
                    </div>
                </myinfo-modify-row>
                <myinfo-modify-row label="주소">
                    <div class="mw-lg-500px">
                        <template v-if="!!form.meta.postcode"> [{{ form.meta.postcode }}] </template>
                        <template v-if="!!form.meta.address1"> {{ form.meta.address1 }} </template>
                        <template v-if="!!form.meta.address2"> {{ form.meta.address2 }} </template>
                    </div>
                </myinfo-modify-row>
            </div>
        </div>
        <!-- 위탁 신청인 정보 -->

        <!-- 위탁 작품 정보 -->
        <div class="mt-30 mt-md-40">
            <div class="mb-10">
                <h3 class="tit tit--xs">위탁 작품 정보</h3>
            </div>
            <div class="table-style table-style--default">
                <myinfo-modify-row label="작가명" pointer>
                    <v-text-field v-model="form.meta.author" outlined persistent-placeholder hide-details class="w-100 mw-lg-500px" />
                </myinfo-modify-row>
                <myinfo-modify-row label="작품명" pointer>
                    <v-text-field v-model="form.meta.subject" outlined persistent-placeholder hide-details class="w-100 mw-lg-500px" />
                </myinfo-modify-row>
                <myinfo-modify-row label="작품의 재료" pointer>
                    <v-text-field v-model="form.meta.material" outlined persistent-placeholder hide-details class="w-100 mw-lg-500px" />
                </myinfo-modify-row>
                <myinfo-modify-row label="작품의 크기" pointer>
                    <v-text-field v-model="form.meta.dimension" outlined persistent-placeholder hide-details class="w-100 mw-lg-500px" />
                </myinfo-modify-row>
                <myinfo-modify-row label="Edition">
                    <v-text-field v-model="form.meta.edition" outlined persistent-placeholder hide-details class="w-100 mw-lg-500px" />
                    <div class="mt-4">
                        <p class="page-text">* 작품이 판화인 경우에 기입해 주세요</p>
                    </div>
                </myinfo-modify-row>
                <myinfo-modify-row label="제작연도(추정)" pointer>
                    <v-text-field v-model="form.meta.year" outlined persistent-placeholder hide-details class="w-100 mw-lg-500px" />
                </myinfo-modify-row>
                <myinfo-modify-row label="작품설명">
                    <v-textarea v-model="form.meta.content" outlined persistent-placeholder hide-details />
                </myinfo-modify-row>
                <myinfo-modify-row label="기타">
                    <v-textarea v-model="form.meta.etc" outlined persistent-placeholder hide-details />
                </myinfo-modify-row>
                <myinfo-modify-row label="작품 이미지" pointer>
                    <images-input v-model="form.files" cols="2" />
                </myinfo-modify-row>
                <myinfo-modify-row label="구입가(KRW)">
                    <v-currency-field v-model="form.meta.price_bought" outlined persistent-placeholder hide-details class="w-100 mw-lg-500px" />
                </myinfo-modify-row>
                <myinfo-modify-row label="희망가(KRW)" pointer>
                    <v-currency-field v-model="form.meta.price_asking" outlined persistent-placeholder hide-details class="w-100 mw-lg-500px" />
                </myinfo-modify-row>
            </div>
        </div>
        <!-- 위탁 작품 정보 -->

        <!-- 하단버튼 -->
        <div class="mt-30 mt-md-50">
            <div class="v-btn--group v-btn--group--large">
                <v-btn x-large outlined color="grey-cb" class="w-100 mw-220px" @click="$router.go(-1)"><span class="primary--text">취소</span></v-btn>
                <v-btn x-large color="primary" class="w-100 mw-220px" @click="save">위탁신청</v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import MyinfoModifyRow from "@/components/client/mypage/myinfo-modify-row.vue";
import ImagesInput from "@/components/console/dumb/images-input.vue";
export default {
    components: {
        MyinfoModifyRow,
        ImagesInput,
    },
    props: {
        value: { type: Object, default: null },
    },
    data() {
        return {
            form: this.$props.value,
        };
    },
    watch: {
        value() {
            this.board = this.value;
        },
    },
    methods: {
        input() {
            this.$emit("input", this.form);
        },
        validate() {
            console.log("validate");
            try {
                if (!this.form?.meta?.author) throw new Error("작가명을 입력하세요");
                if (!this.form?.meta?.subject) throw new Error("작품명을 입력하세요");
                if (!this.form?.meta?.material) throw new Error("작품의 재료를 입력하세요");
                if (!this.form?.meta?.dimension) throw new Error("작품의 크기를 입력하세요");
                if (!this.form?.meta?.year) throw new Error("제작연도를 입력하세요");
                if ((this.form?.files || []).length < 1) throw new Error("작품 이미지를 업로드하세요");
                if ((this.form?.meta?.price_asking || 0) < 1) throw new Error("희망가를 입력하세요");

                return true;
            } catch (error) {
                alert(error.message);
                return false;
            }
        },
        save() {
            if (!this.validate()) return;
            this.$emit("save");
        },
    },
};
</script>
