var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-container"
  }, [_c('div', [_c('v-card', {
    staticClass: "grey-ef",
    attrs: {
      "tile": "",
      "elevation": "0"
    }
  }, [_c('v-card-text', {
    staticClass: "pa-24 pa-md-30"
  }, [_c('div', {
    staticClass: "mb-10 mb-md-14"
  }, [_c('h4', {
    staticClass: "tit tit--xs primary--text"
  }, [_vm._v("* 위탁 신청 전에 읽어주세요")])]), _c('div', {
    staticClass: "page-text"
  }, [_c('p', [_vm._v("1. 심의 항목 : 작품성, 작품상태, 판매가능성, 작품가치, 판매대상 범위와의 부합성 등 다양한 측면을 고려하여 서면심의를 진행합니다")]), _c('p', [_vm._v("2. 심의 기간 : 접수일로부터 7일 이내 심의를 완료합니다")]), _c('p', [_vm._v("3. 심의 결과 안내 : 위탁신청 접수 내용을 토대로 심의 후, 위탁 가능 여부를 위탁자에게 개별 안내해 드립니다")])])])], 1)], 1), _c('div', {
    staticClass: "mt-30 mt-md-40"
  }, [_vm._m(0), _c('div', {
    staticClass: "table-style table-style--default"
  }, [_c('myinfo-modify-row', {
    attrs: {
      "label": "이름"
    }
  }, [_vm._v(" " + _vm._s(_vm.form.meta.name) + " ")]), _c('myinfo-modify-row', {
    attrs: {
      "label": "연락처"
    }
  }, [_c('div', {
    staticClass: "mw-lg-500px"
  }, [_vm._v(" " + _vm._s(_vm.form.meta.phone) + " ")])]), _c('myinfo-modify-row', {
    attrs: {
      "label": "이메일"
    }
  }, [_c('div', {
    staticClass: "mw-lg-500px"
  }, [_vm._v(" " + _vm._s(_vm.form.meta.email) + " ")])]), _c('myinfo-modify-row', {
    attrs: {
      "label": "주소"
    }
  }, [_c('div', {
    staticClass: "mw-lg-500px"
  }, [!!_vm.form.meta.postcode ? [_vm._v(" [" + _vm._s(_vm.form.meta.postcode) + "] ")] : _vm._e(), !!_vm.form.meta.address1 ? [_vm._v(" " + _vm._s(_vm.form.meta.address1) + " ")] : _vm._e(), !!_vm.form.meta.address2 ? [_vm._v(" " + _vm._s(_vm.form.meta.address2) + " ")] : _vm._e()], 2)])], 1)]), _c('div', {
    staticClass: "mt-30 mt-md-40"
  }, [_vm._m(1), _c('div', {
    staticClass: "table-style table-style--default"
  }, [_c('myinfo-modify-row', {
    attrs: {
      "label": "작가명",
      "pointer": ""
    }
  }, [_c('v-text-field', {
    staticClass: "w-100 mw-lg-500px",
    attrs: {
      "outlined": "",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.form.meta.author,
      callback: function ($$v) {
        _vm.$set(_vm.form.meta, "author", $$v);
      },
      expression: "form.meta.author"
    }
  })], 1), _c('myinfo-modify-row', {
    attrs: {
      "label": "작품명",
      "pointer": ""
    }
  }, [_c('v-text-field', {
    staticClass: "w-100 mw-lg-500px",
    attrs: {
      "outlined": "",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.form.meta.subject,
      callback: function ($$v) {
        _vm.$set(_vm.form.meta, "subject", $$v);
      },
      expression: "form.meta.subject"
    }
  })], 1), _c('myinfo-modify-row', {
    attrs: {
      "label": "작품의 재료",
      "pointer": ""
    }
  }, [_c('v-text-field', {
    staticClass: "w-100 mw-lg-500px",
    attrs: {
      "outlined": "",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.form.meta.material,
      callback: function ($$v) {
        _vm.$set(_vm.form.meta, "material", $$v);
      },
      expression: "form.meta.material"
    }
  })], 1), _c('myinfo-modify-row', {
    attrs: {
      "label": "작품의 크기",
      "pointer": ""
    }
  }, [_c('v-text-field', {
    staticClass: "w-100 mw-lg-500px",
    attrs: {
      "outlined": "",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.form.meta.dimension,
      callback: function ($$v) {
        _vm.$set(_vm.form.meta, "dimension", $$v);
      },
      expression: "form.meta.dimension"
    }
  })], 1), _c('myinfo-modify-row', {
    attrs: {
      "label": "Edition"
    }
  }, [_c('v-text-field', {
    staticClass: "w-100 mw-lg-500px",
    attrs: {
      "outlined": "",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.form.meta.edition,
      callback: function ($$v) {
        _vm.$set(_vm.form.meta, "edition", $$v);
      },
      expression: "form.meta.edition"
    }
  }), _c('div', {
    staticClass: "mt-4"
  }, [_c('p', {
    staticClass: "page-text"
  }, [_vm._v("* 작품이 판화인 경우에 기입해 주세요")])])], 1), _c('myinfo-modify-row', {
    attrs: {
      "label": "제작연도(추정)",
      "pointer": ""
    }
  }, [_c('v-text-field', {
    staticClass: "w-100 mw-lg-500px",
    attrs: {
      "outlined": "",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.form.meta.year,
      callback: function ($$v) {
        _vm.$set(_vm.form.meta, "year", $$v);
      },
      expression: "form.meta.year"
    }
  })], 1), _c('myinfo-modify-row', {
    attrs: {
      "label": "작품설명"
    }
  }, [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.form.meta.content,
      callback: function ($$v) {
        _vm.$set(_vm.form.meta, "content", $$v);
      },
      expression: "form.meta.content"
    }
  })], 1), _c('myinfo-modify-row', {
    attrs: {
      "label": "기타"
    }
  }, [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.form.meta.etc,
      callback: function ($$v) {
        _vm.$set(_vm.form.meta, "etc", $$v);
      },
      expression: "form.meta.etc"
    }
  })], 1), _c('myinfo-modify-row', {
    attrs: {
      "label": "작품 이미지",
      "pointer": ""
    }
  }, [_c('images-input', {
    attrs: {
      "cols": "2"
    },
    model: {
      value: _vm.form.files,
      callback: function ($$v) {
        _vm.$set(_vm.form, "files", $$v);
      },
      expression: "form.files"
    }
  })], 1), _c('myinfo-modify-row', {
    attrs: {
      "label": "구입가(KRW)"
    }
  }, [_c('v-currency-field', {
    staticClass: "w-100 mw-lg-500px",
    attrs: {
      "outlined": "",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.form.meta.price_bought,
      callback: function ($$v) {
        _vm.$set(_vm.form.meta, "price_bought", $$v);
      },
      expression: "form.meta.price_bought"
    }
  })], 1), _c('myinfo-modify-row', {
    attrs: {
      "label": "희망가(KRW)",
      "pointer": ""
    }
  }, [_c('v-currency-field', {
    staticClass: "w-100 mw-lg-500px",
    attrs: {
      "outlined": "",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.form.meta.price_asking,
      callback: function ($$v) {
        _vm.$set(_vm.form.meta, "price_asking", $$v);
      },
      expression: "form.meta.price_asking"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "mt-30 mt-md-50"
  }, [_c('div', {
    staticClass: "v-btn--group v-btn--group--large"
  }, [_c('v-btn', {
    staticClass: "w-100 mw-220px",
    attrs: {
      "x-large": "",
      "outlined": "",
      "color": "grey-cb"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('span', {
    staticClass: "primary--text"
  }, [_vm._v("취소")])]), _c('v-btn', {
    staticClass: "w-100 mw-220px",
    attrs: {
      "x-large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("위탁신청")])], 1)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-10"
  }, [_c('h3', {
    staticClass: "tit tit--xs"
  }, [_vm._v("위탁 신청인 정보")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-10"
  }, [_c('h3', {
    staticClass: "tit tit--xs"
  }, [_vm._v("위탁 작품 정보")])]);

}]

export { render, staticRenderFns }